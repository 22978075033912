<template>
  <div class="flex items-center justify-center h-screen flex-grow p-4">
    <section class="px-4 py-24 mx-auto max-w-7xl">
      <div
        class="
          grid
          items-center
          w-full
          grid-cols-1
          gap-10
          mx-auto
          md:w-4/5
          lg:grid-cols-2
          xl:gap-32
        "
      >
        <div>
          <p
            class="
              mb-2
              text-xs
              font-semibold
              tracking-wide
              text-gray-500
              uppercase
            "
          >
            Error 404
          </p>
          <h1
            class="
              mb-4
              text-2xl
              font-extrabold
              leading-tight
              tracking-tight
              text-left text-gray-900
              md:text-4xl
            "
          >
            Ops! A página que você está procurando não está aqui.
          </h1>
          <p class="mb-5 text-base text-left text-gray-800 md:text-xl">
            Você pode ter digitado o endereço errado ou talvez o endereço não
            esteja mais disponível na web
          </p>
          <router-link
            :to="{ name: 'home' }"
            class="
              w-full
              mb-2
              btn btn-lg
              bg-green-300
              p-2
              rounded-md
              text-gray-800
              btn-light
              sm:w-auto sm:mb-0
            "
            >Voltar à página inicial
          </router-link>
        </div>
        <div>
          <img src="@/assets/images/404.svg" alt="404" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>